import type { AppModule } from '@/types'
import { zxcvbnOptions } from '@zxcvbn-ts/core'
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common'

export default {
  install: () => {
    const options = {
      // recommended
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
      },
      // recommended
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      // recommended
      useLevenshteinDistance: true,
    }

    zxcvbnOptions.setOptions(options)
  },
} as AppModule
