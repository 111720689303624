import type { AppModule } from '@/types'
import style from '@/assets/map/style.json'
import MapLibrePlugin from '@/components/MapLibre/maplibrePlugin'

export default {
  install: (app) => {
    app.use(MapLibrePlugin, {
      style,
      i18n: true,
    })
  },
} as AppModule
