import type { AppModule } from '@/types'
import { ChatboxColors, Crisp } from 'crisp-sdk-web'

export default {
  install(app) {
    const WEBSITE_ID = import.meta.env.VITE_CRISP_WEBSITE_ID

    if (WEBSITE_ID) {
      Crisp.configure(WEBSITE_ID, {
        autoload: false,
      })
      Crisp.setColorTheme(ChatboxColors.Blue)
      Crisp.setHideOnMobile(true)

      if (IS_MOBILE) {
        Crisp.chat.onChatClosed(() => {
          Crisp.chat.hide()
        })
      }

      app.config.globalProperties.crisp = Crisp
      app.provide('crisp', Crisp)
    } else {
      app.config.globalProperties.crisp = null
      app.provide('crisp', null)
    }
  },
} as AppModule
